import React from 'react'
import ArchiveFilesTable from './archivefilestable'
import AddArchiveOwners from './addarchiveowners'
import UpdateArchiveOwners from './updatearchiveowners'
import VaultExpirySelector from './vaultexpiryselector'
import UpdateArchiveExpiry from './updatearchiveexpiry'
import DeleteArchiveButton from './deletearchivebutton'
import KeyVaultFacts from './keyvaultfacts'
import Uploader from './uploader'
import Tooltip from './tooltip'
import { navigate } from 'gatsby'

// Displays the details for a specific vault and provides the tools for
// changing them.
class SpecificArchiveDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      owners: this.props.details.owners,
      archive: '',
      expires: this.props.details.expiryDateSeconds,
      uploadingExtraFiles: false,
      tmpOwners: null,
      alertClassString: '',
      tmpExpires: null,
      response: null,
      apiResponseStatus: 0,
      archiveUpdated: false,
      size: 'Calculating...'
   };

    this.ownersCallback = this.ownersCallback.bind(this);
    this.expiryDateCallback = this.expiryDateCallback.bind(this);
    this.ownersResponseCallback = this.ownersResponseCallback.bind(this);
    this.sizeCallback = this.sizeCallback.bind(this);
    this.expiresResponseCallback = this.expiresResponseCallback.bind(this);
    this.renderRequestPage = this.renderRequestPage.bind(this);
  }

  // Transfer archiveid through to the request uploads page, as it needs this
  // information for its POST /requests request.
  renderRequestPage() {
    navigate('/request',
    {
      state: { archiveid: this.props.details.archiveid,
               title: this.props.details.title }
    });
  }

  ownersCallback(tmpOwners) {
    this.setState({
      tmpOwners: tmpOwners
    });
  }

  sizeCallback(size) {
    this.setState({
      size: size
    });
  }

  expiryDateCallback(tmpExpires) {
    this.setState({
      tmpExpires: tmpExpires
    });
  }

  async ownersResponseCallback(response) {
    let message = await response.json();

    const apiResponseStatus = response.status;

    if (apiResponseStatus === 200) {
      var alertClassString = 'c-alert c-alert--success';

      // Await to stop this update happening at undesired times.
      await this.setState({
        owners: this.state.tmpOwners
      });
    } else {
      alertClassString = 'c-alert c-alert--danger c-alert'
    }

    this.setState({
      apiResponseMessage: message.message,
      alertClassString: alertClassString,
      apiResponseStatus: apiResponseStatus,
      archiveUpdated: true
    });
  }

  async expiresResponseCallback(response) {
    let message = await response.json();

    const apiResponseStatus = response.status;

    if (apiResponseStatus === 200) {
      var alertClassString = 'c-alert c-alert--success';

      this.setState({
        expires: this.state.tmpExpires
      });
    } else {
      alertClassString = 'c-alert c-alert--danger c-alert'
    }

    this.setState({
      apiResponseMessage: message.message,
      alertClassString: alertClassString,
      apiResponseStatus: apiResponseStatus,
      archiveUpdated: true
    });
  }

  render() {
    if (this.props.details) {
        const { title, owners, archiveid, department, expiryDateSeconds } = this.props.details;

      return(
        <React.Fragment>
          { this.state.archiveUpdated &&
            <div class={this.state.alertClassString} role='alert'>
              <div class='c-alert__content'>
                <strong>{this.state.apiResponseMessage}</strong>
              </div>
            </div>
          }
          <label><h3>Details for {title}</h3></label>

          <br />

          <KeyVaultFacts owners={this.state.owners} expiryDate={this.state.expires} size={this.state.size} />

          <br />

          <label class='c-form__label'>Owning department: {department}</label>

	  <p />
          <br />
          <hr />
          <br />

          <label class='c-form__label'>Modify vault owners</label>

          <AddArchiveOwners
            callback={this.ownersCallback}
            initialArchiveOwners={owners}
          />

          <UpdateArchiveOwners
            owners={this.state.tmpOwners}
            callback={this.ownersResponseCallback}
            archiveid={archiveid}
          />

	  <p />
          <br />
          <hr />
          <br />

          <label class='c-form__label'>Modify vault expiry date</label>

          <br />

          <VaultExpirySelector
            expirydate={expiryDateSeconds}
            callback={this.expiryDateCallback} />


          <p />
	  <UpdateArchiveExpiry expires={this.state.tmpExpires} callback={this.expiresResponseCallback} archiveid={archiveid} />
	  <p />
          <br />
          <hr />
          <br />
          <label class='c-form__label'>Files</label>
          <ArchiveFilesTable archiveid={archiveid} callback={this.sizeCallback} />
          <p />
          <button class='c-btn c-btn--success c-btn--medium' onClick={this.renderRequestPage}>Request new upload</button>
          <p />
          <p>Alternatively, you can upload some files yourself (works only for a few MBs max):&nbsp;
             <Tooltip>If you upload the same file more than once, multiple versions will be stored in your vault.</Tooltip>
          </p>
          <Uploader archiveid={archiveid}/>
        </React.Fragment>);
    }

    return null;
  }
}

export default SpecificArchiveDetails
